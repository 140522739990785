import { Loader } from "@googlemaps/js-api-loader";

const MapsLoader = new Loader({
  apiKey: "AIzaSyCNFSebRQR4JK1qvH-yRGolz07ps57BAyA",
  version: "weekly",
  libraries: ["places"],
});

let loading = MapsLoader.load().then((e) => {
    console.log("Connected to google maps")
    return true
})


export default loading