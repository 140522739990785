<template>
  <v-card flat>
    <div
      style="height: 100vh"
      class="d-flex justify-center align-center"
      v-if="mapLoading"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-card-text v-else>
      <div class="map-container">
        <div id="map" style="height: 30em"></div>
      </div>
      <div class="input-container mt-10">
        <v-row>
          <v-col cols="12" md="6">
            <Autocomplete :returnLatLng="returnLatLng" autocompleteType="Source" />
          </v-col>
          <v-col cols="12" md="6">
            <Autocomplete :returnLatLng="returnLatLng" autocompleteType="Destination" />
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn v-if="showConfirmLocation" color="success" @click="testThing()">Confirm Location</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import loading from '../../mapsLoader'

export default {

  props: {
    getMapData: {
      type: Function,
      required: false
    }
  },

  components: {
    Autocomplete: () => import('@/components/shared/Autocomplete.vue')
  },

  data() {
    return {
      map: null,
      mapLoading: true,
      config: {
        center: { lat: 14.7251902, lng: -17.4596118 },
        zoom: 13,
        disableDefaultUI: true,
      },
      tripDistance: null,
      tripTime: null,
      sourceMarker: null,
      sourceMarkers: [],
      destMarker: null,
      destMarkers: [],
      showConfirmLocation: true,
      markers: [
        {
          name: '',
          lat: 0,
          lng: 0
        },
        {
          name: '',
          lat: 0,
          lng: 0
        }
      ]
    }
  },

  
  created() {
    loading.then(res => {
      if(res) {
        this.mapLoading = false
        setTimeout(() => {
          this.initMap()
        }, 1000)
      }
    })
  },

  
  methods: {
    initMap() {
      if(google && google.maps){
      const mapContainer = document.getElementById("map")
      this.map = new google.maps.Map(mapContainer, this.config)
      }
    },

    async testThing() {

      console.log(this.markers)

      const directionsService = new google.maps.DirectionsService();
      const directionsRenderer = new google.maps.DirectionsRenderer();

      // this.resetLocationMarkers()

      directionsService
          .route({
            origin: new google.maps.LatLng(`${this.markers[0].lat}`, `${this.markers[0].lng}`),
            destination: new google.maps.LatLng(`${this.markers[1].lat}`, `${this.markers[1].lng}`),
            travelMode: google.maps.TravelMode.DRIVING,
          })
          .then((response) => {
            directionsRenderer.setDirections(response);
          });

        directionsRenderer.setMap(this.map);
        await this.getDistanceAndTime()
        console.log(this.tripDistance, this.tripTime)

        this.getMapData({
          tripTime: this.tripTime,
          tripDistance: this.tripDistance,
          pickUp: this.markers[0],
          dropOff: this.markers[1],
        })
        this.showConfirmLocation = false

    },

    resetLocationMarkers() {
      this.sourceMarkers.splice(0, 1)
      this.sourceMarker.setMap(null)
      this.destMarkers.splice(0, 1)
      this.destMarker.setMap(null)
    },

    getDistanceAndTime() {
      const request = {
        origins: [new google.maps.LatLng(`${this.markers[0].lat}`, `${this.markers[0].lng}`)],
        destinations: [new google.maps.LatLng(`${this.markers[1].lat}`, `${this.markers[1].lng}`)],
        travelMode: google.maps.TravelMode.DRIVING,
        avoidHighways: false,
        avoidTolls: false,
      }

      return new Promise((resolve, reject) => {
        new google.maps.DistanceMatrixService().getDistanceMatrix(request).then((response) => {

          this.tripDistance = response.rows[0].elements[0].distance.text
          this.tripTime = response.rows[0].elements[0].duration.text
          resolve()
        }).catch(err => {
          reject(err)
        })
      })
    },

    returnSourceMarker(marker) {

      if(this.sourceMarkers.length > 0) {
        this.sourceMarkers.splice(0, 1)
        this.sourceMarker.setMap(null)
      }

      setTimeout(() => {
        this.sourceMarker = new google.maps.Marker({
          position: { lat: marker.lat, lng: marker.lng },
          map: this.map,
          draggable: true
        })

        this.sourceMarkers.push(this.sourceMarker)


        new google.maps.event.addListener(this.sourceMarker, 'dragend', (evt) => {
          let lat = evt.latLng.lat().toFixed(6)
          let lng = evt.latLng.lng().toFixed(6)

          this.markers[0].lat = lat
          this.markers[0].lng = lng
        })
      }, 1000)

    },

    returnDestMarker(marker) {

      if(this.destMarkers.length > 0) {
        this.destMarkers.splice(0, 1)
        this.destMarker.setMap(null)
      }

      setTimeout(() => {
        this.destMarker = new google.maps.Marker({
          position: { lat: marker.lat, lng: marker.lng },
          map: this.map,
          draggable: true
          })

          this.destMarkers.push(this.sourceMarker)

          google.maps.event.addListener(this.destMarker, 'dragend', (evt) => {
            let lat = evt.latLng.lat().toFixed(6)
            let lng = evt.latLng.lng().toFixed(6)

            this.markers[1].lat = lat
            this.markers[1].lng = lng
          })
      }, 1000)
    },

    returnLatLng(type, payload) {
      if(type == 'Source') {
        this.markers[0] = payload
        this.returnSourceMarker(payload)
      } else {
        this.markers[1] = payload
        this.returnDestMarker(payload)
      }
    },

    testMarkers(marker) {
      new google.maps.Marker({
        title: marker.name,
        position: { lat: marker.lat, lng: marker.lng },
        map: this.map,
        draggable: true
      })
    }
  }

}
</script>

<style>

</style>